<template>
  <div class="relative">
    <div v-if="initials" class="w-10 h-10 rounded-full opacity-30 flex" :style="{'background-color': color}">
      <span class="text-lg font-bold text-white self-center w-full text-center">{{ initials }}</span>
    </div>
    <UserIcon v-else class="w-8 h-8 p-1 text-white bg-gray-300 rounded-full" />
  </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue'
import { UserIcon } from '@heroicons/vue/outline'

export default defineComponent({
  components: {
    UserIcon
  },
  props: {
    firstname: String,
    lastname: String,
    color: String
  },
  setup(props) {
    return {
      initials: computed(() => `${props.firstname ? props.firstname[0] : ''}${props.lastname ? props.lastname[0] : ''}`),
      color: computed(() => props.color || '#f3f4f6')
    }
  }

})
</script>